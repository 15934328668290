import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../layout/layout'
import Summary from '../components/summary'
import Info from '../components/contact-section'

const Page = ({ pageContext, data }) => (
  <Layout contextEntity={pageContext.page} cssClass="contact">
    <Summary data={pageContext.page} />
    <div id="main-content">
      <Info />
    </div>
  </Layout>
)

export default Page

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        telephone
        email
        address
        social {
          facebook
          instagram
          linkedin
          twitter
        }
      }
    }
  }
`
