import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import DbHelmet from '../components/db/db-helmet'
import DbSection from '../components/db/db-section'
import Header from '../layout/header'
import Footer from '../layout/footer'
import favicon from '../images/favicon.png'



const Layout = ({ contextEntity = {}, cssClass, children }) => (
  <div className={cssClass}>
    {contextEntity.metaDetails ? (
      <DbHelmet data={contextEntity.metaDetails} />
    ) : (
      <Helmet>
        <title>{contextEntity.pageTitle ? contextEntity.pageTitle : 'Websites and web applications that perform | DoubleBrace'}</title>
        <script>{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-KW7P88L');`}</script>
        <meta name="google-site-verification" content="iw6XAInk_hQXW6jSVJWqcDLc-Fb4Y0sq9OuMLyOWAoQ" />
        <link rel="shortcut icon" href={favicon} />
        <html lang="en" />
      </Helmet>
    )}
    <Header />
    <div className={`${cssClass}-layout layout`}>
      {children}
      {contextEntity.sections
        ? contextEntity.sections.map(function(section, i) {
            return <DbSection data={section} key={i} />
          })
        : ''}
      <Footer pageTitle={contextEntity.pageTitle} />
    </div>
  </div>
)

Layout.propTypes = {
  //cssClass: PropTypes.string.isRequired,
  contextEntity: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
}

export default Layout
